<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-card outlined>
        <v-toolbar flat>
          <v-list-item class="pl-0 pr-0">
            <v-list-item-avatar
              :color="teamMember.status === 1 ? 'accent' : 'grey'"
            >
              <img
                v-if="teamMember.user && teamMember.user.avatar"
                :src="getAvatar(teamMember.user.avatar)"
                :alt="teamMember.full_name"
              />
              <span v-else class="white--text">{{ teamMember.initials }}</span>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ teamMember.full_name }}</v-list-item-title>
              <v-list-item-subtitle>{{
                teamMember.email
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <template v-slot:extension>
            <v-tabs>
              <v-tab :to="{ name: 'module-team-profile' }">
                <v-icon left>mdi-account-details</v-icon>Profile Details
              </v-tab>
              <v-tab :to="{ name: 'module-team-profile-contact' }">
                <v-icon left>mdi-phone</v-icon>Contact Info
              </v-tab>
              <v-tab :to="{ name: 'module-team-profile-contract' }">
                <v-icon left>mdi-file-document-edit</v-icon>Contract Details
              </v-tab>
              <v-tab :to="{ name: 'module-team-profile-user-access' }">
                <v-icon left>mdi-key</v-icon>User Access
              </v-tab>
              <v-tab :to="{ name: 'module-team-profile-time-logged' }">
                <v-icon left>mdi-timer</v-icon>Time Logged
              </v-tab>
              <v-tab :to="{ name: 'module-team-profile-integrations' }">
                <v-icon left>mdi-puzzle</v-icon>Integrations
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-divider></v-divider>
        <div class="pa-5">
          <router-view v-bind:teamMember="teamMember"></router-view>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "Team",
          disabled: false,
          exact: true,
          to: { name: "module-team", params: { id: this.$route.params.id } },
        },
      ],
    };
  },

  computed: {
    teamMember() {
      return this.$store.state.team["teamMember"];
    },
  },

  methods: {
    getAvatar: function(avatarUrl) {
      return `${process.env.VUE_APP_ACCOUNTS_URL}/storage/${avatarUrl}`;
    },
  },
};
</script>
